/**
 * @fileoverview gRPC-Web generated client stub for order_reported_problem
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.21.12
// source: protobuf/order_reported_problem/type.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as protobuf_model_merged_pb from '../../protobuf/model/merged_pb';
import * as protobuf_order_reported_problem_type_pb from '../../protobuf/order_reported_problem/type_pb';


export class OrderReportedProblemServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreate = new grpcWeb.MethodDescriptor(
    '/order_reported_problem.OrderReportedProblemService/Create',
    grpcWeb.MethodType.UNARY,
    protobuf_order_reported_problem_type_pb.CreateOrderReportedProblemRequest,
    protobuf_model_merged_pb.OrderReportedProblemResponse,
    (request: protobuf_order_reported_problem_type_pb.CreateOrderReportedProblemRequest) => {
      return request.serializeBinary();
    },
    protobuf_model_merged_pb.OrderReportedProblemResponse.deserializeBinary
  );

  create(
    request: protobuf_order_reported_problem_type_pb.CreateOrderReportedProblemRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_model_merged_pb.OrderReportedProblemResponse>;

  create(
    request: protobuf_order_reported_problem_type_pb.CreateOrderReportedProblemRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.OrderReportedProblemResponse) => void): grpcWeb.ClientReadableStream<protobuf_model_merged_pb.OrderReportedProblemResponse>;

  create(
    request: protobuf_order_reported_problem_type_pb.CreateOrderReportedProblemRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.OrderReportedProblemResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order_reported_problem.OrderReportedProblemService/Create',
        request,
        metadata || {},
        this.methodDescriptorCreate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order_reported_problem.OrderReportedProblemService/Create',
    request,
    metadata || {},
    this.methodDescriptorCreate);
  }

  methodDescriptorUpdate = new grpcWeb.MethodDescriptor(
    '/order_reported_problem.OrderReportedProblemService/Update',
    grpcWeb.MethodType.UNARY,
    protobuf_order_reported_problem_type_pb.UpdateOrderReportedProblemRequest,
    protobuf_model_merged_pb.OrderReportedProblemResponse,
    (request: protobuf_order_reported_problem_type_pb.UpdateOrderReportedProblemRequest) => {
      return request.serializeBinary();
    },
    protobuf_model_merged_pb.OrderReportedProblemResponse.deserializeBinary
  );

  update(
    request: protobuf_order_reported_problem_type_pb.UpdateOrderReportedProblemRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_model_merged_pb.OrderReportedProblemResponse>;

  update(
    request: protobuf_order_reported_problem_type_pb.UpdateOrderReportedProblemRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.OrderReportedProblemResponse) => void): grpcWeb.ClientReadableStream<protobuf_model_merged_pb.OrderReportedProblemResponse>;

  update(
    request: protobuf_order_reported_problem_type_pb.UpdateOrderReportedProblemRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.OrderReportedProblemResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order_reported_problem.OrderReportedProblemService/Update',
        request,
        metadata || {},
        this.methodDescriptorUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order_reported_problem.OrderReportedProblemService/Update',
    request,
    metadata || {},
    this.methodDescriptorUpdate);
  }

  methodDescriptorGet = new grpcWeb.MethodDescriptor(
    '/order_reported_problem.OrderReportedProblemService/Get',
    grpcWeb.MethodType.UNARY,
    protobuf_order_reported_problem_type_pb.GetOrderReportedProblemsRequest,
    protobuf_order_reported_problem_type_pb.GetOrderReportedProblemsResponse,
    (request: protobuf_order_reported_problem_type_pb.GetOrderReportedProblemsRequest) => {
      return request.serializeBinary();
    },
    protobuf_order_reported_problem_type_pb.GetOrderReportedProblemsResponse.deserializeBinary
  );

  get(
    request: protobuf_order_reported_problem_type_pb.GetOrderReportedProblemsRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_order_reported_problem_type_pb.GetOrderReportedProblemsResponse>;

  get(
    request: protobuf_order_reported_problem_type_pb.GetOrderReportedProblemsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_order_reported_problem_type_pb.GetOrderReportedProblemsResponse) => void): grpcWeb.ClientReadableStream<protobuf_order_reported_problem_type_pb.GetOrderReportedProblemsResponse>;

  get(
    request: protobuf_order_reported_problem_type_pb.GetOrderReportedProblemsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_order_reported_problem_type_pb.GetOrderReportedProblemsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order_reported_problem.OrderReportedProblemService/Get',
        request,
        metadata || {},
        this.methodDescriptorGet,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order_reported_problem.OrderReportedProblemService/Get',
    request,
    metadata || {},
    this.methodDescriptorGet);
  }

  methodDescriptorDelete = new grpcWeb.MethodDescriptor(
    '/order_reported_problem.OrderReportedProblemService/Delete',
    grpcWeb.MethodType.UNARY,
    protobuf_order_reported_problem_type_pb.DeleteOrderReportedProblemsRequest,
    protobuf_order_reported_problem_type_pb.DeleteOrderReportedProblemsResponse,
    (request: protobuf_order_reported_problem_type_pb.DeleteOrderReportedProblemsRequest) => {
      return request.serializeBinary();
    },
    protobuf_order_reported_problem_type_pb.DeleteOrderReportedProblemsResponse.deserializeBinary
  );

  delete(
    request: protobuf_order_reported_problem_type_pb.DeleteOrderReportedProblemsRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_order_reported_problem_type_pb.DeleteOrderReportedProblemsResponse>;

  delete(
    request: protobuf_order_reported_problem_type_pb.DeleteOrderReportedProblemsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_order_reported_problem_type_pb.DeleteOrderReportedProblemsResponse) => void): grpcWeb.ClientReadableStream<protobuf_order_reported_problem_type_pb.DeleteOrderReportedProblemsResponse>;

  delete(
    request: protobuf_order_reported_problem_type_pb.DeleteOrderReportedProblemsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_order_reported_problem_type_pb.DeleteOrderReportedProblemsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order_reported_problem.OrderReportedProblemService/Delete',
        request,
        metadata || {},
        this.methodDescriptorDelete,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order_reported_problem.OrderReportedProblemService/Delete',
    request,
    metadata || {},
    this.methodDescriptorDelete);
  }

  methodDescriptorList = new grpcWeb.MethodDescriptor(
    '/order_reported_problem.OrderReportedProblemService/List',
    grpcWeb.MethodType.UNARY,
    protobuf_order_reported_problem_type_pb.ListOrderReportedProblemsRequest,
    protobuf_order_reported_problem_type_pb.ListOrderReportedProblemsResponse,
    (request: protobuf_order_reported_problem_type_pb.ListOrderReportedProblemsRequest) => {
      return request.serializeBinary();
    },
    protobuf_order_reported_problem_type_pb.ListOrderReportedProblemsResponse.deserializeBinary
  );

  list(
    request: protobuf_order_reported_problem_type_pb.ListOrderReportedProblemsRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_order_reported_problem_type_pb.ListOrderReportedProblemsResponse>;

  list(
    request: protobuf_order_reported_problem_type_pb.ListOrderReportedProblemsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_order_reported_problem_type_pb.ListOrderReportedProblemsResponse) => void): grpcWeb.ClientReadableStream<protobuf_order_reported_problem_type_pb.ListOrderReportedProblemsResponse>;

  list(
    request: protobuf_order_reported_problem_type_pb.ListOrderReportedProblemsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_order_reported_problem_type_pb.ListOrderReportedProblemsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order_reported_problem.OrderReportedProblemService/List',
        request,
        metadata || {},
        this.methodDescriptorList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order_reported_problem.OrderReportedProblemService/List',
    request,
    metadata || {},
    this.methodDescriptorList);
  }

  methodDescriptorExport = new grpcWeb.MethodDescriptor(
    '/order_reported_problem.OrderReportedProblemService/Export',
    grpcWeb.MethodType.UNARY,
    protobuf_order_reported_problem_type_pb.ExportOrderReportedProblemsRequest,
    protobuf_order_reported_problem_type_pb.ExportOrderReportedProblemsResponse,
    (request: protobuf_order_reported_problem_type_pb.ExportOrderReportedProblemsRequest) => {
      return request.serializeBinary();
    },
    protobuf_order_reported_problem_type_pb.ExportOrderReportedProblemsResponse.deserializeBinary
  );

  export(
    request: protobuf_order_reported_problem_type_pb.ExportOrderReportedProblemsRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_order_reported_problem_type_pb.ExportOrderReportedProblemsResponse>;

  export(
    request: protobuf_order_reported_problem_type_pb.ExportOrderReportedProblemsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_order_reported_problem_type_pb.ExportOrderReportedProblemsResponse) => void): grpcWeb.ClientReadableStream<protobuf_order_reported_problem_type_pb.ExportOrderReportedProblemsResponse>;

  export(
    request: protobuf_order_reported_problem_type_pb.ExportOrderReportedProblemsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_order_reported_problem_type_pb.ExportOrderReportedProblemsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order_reported_problem.OrderReportedProblemService/Export',
        request,
        metadata || {},
        this.methodDescriptorExport,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order_reported_problem.OrderReportedProblemService/Export',
    request,
    metadata || {},
    this.methodDescriptorExport);
  }

}

