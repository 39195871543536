// source: protobuf/order_reported_problem/type.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var protobuf_validate_validate_pb = require('../../protobuf/validate/validate_pb.js');
goog.object.extend(proto, protobuf_validate_validate_pb);
var protobuf_filter_counters_pb = require('../../protobuf/filter/counters_pb.js');
goog.object.extend(proto, protobuf_filter_counters_pb);
var protobuf_filter_export_pb = require('../../protobuf/filter/export_pb.js');
goog.object.extend(proto, protobuf_filter_export_pb);
var protobuf_filter_request_pb = require('../../protobuf/filter/request_pb.js');
goog.object.extend(proto, protobuf_filter_request_pb);
var protobuf_model_merged_pb = require('../../protobuf/model/merged_pb.js');
goog.object.extend(proto, protobuf_model_merged_pb);
goog.exportSymbol('proto.order_reported_problem.CreateOrderReportedProblemRequest', null, global);
goog.exportSymbol('proto.order_reported_problem.DeleteOrderReportedProblemsRequest', null, global);
goog.exportSymbol('proto.order_reported_problem.DeleteOrderReportedProblemsResponse', null, global);
goog.exportSymbol('proto.order_reported_problem.ExportOrderReportedProblemsRequest', null, global);
goog.exportSymbol('proto.order_reported_problem.ExportOrderReportedProblemsResponse', null, global);
goog.exportSymbol('proto.order_reported_problem.GetOrderReportedProblemsRequest', null, global);
goog.exportSymbol('proto.order_reported_problem.GetOrderReportedProblemsResponse', null, global);
goog.exportSymbol('proto.order_reported_problem.ListOrderReportedProblemsRequest', null, global);
goog.exportSymbol('proto.order_reported_problem.ListOrderReportedProblemsResponse', null, global);
goog.exportSymbol('proto.order_reported_problem.UpdateOrderReportedProblemRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_reported_problem.CreateOrderReportedProblemRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order_reported_problem.CreateOrderReportedProblemRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_reported_problem.CreateOrderReportedProblemRequest.displayName = 'proto.order_reported_problem.CreateOrderReportedProblemRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_reported_problem.UpdateOrderReportedProblemRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order_reported_problem.UpdateOrderReportedProblemRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_reported_problem.UpdateOrderReportedProblemRequest.displayName = 'proto.order_reported_problem.UpdateOrderReportedProblemRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_reported_problem.GetOrderReportedProblemsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.order_reported_problem.GetOrderReportedProblemsRequest.repeatedFields_, null);
};
goog.inherits(proto.order_reported_problem.GetOrderReportedProblemsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_reported_problem.GetOrderReportedProblemsRequest.displayName = 'proto.order_reported_problem.GetOrderReportedProblemsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_reported_problem.GetOrderReportedProblemsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order_reported_problem.GetOrderReportedProblemsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_reported_problem.GetOrderReportedProblemsResponse.displayName = 'proto.order_reported_problem.GetOrderReportedProblemsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_reported_problem.DeleteOrderReportedProblemsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.order_reported_problem.DeleteOrderReportedProblemsRequest.repeatedFields_, null);
};
goog.inherits(proto.order_reported_problem.DeleteOrderReportedProblemsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_reported_problem.DeleteOrderReportedProblemsRequest.displayName = 'proto.order_reported_problem.DeleteOrderReportedProblemsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_reported_problem.DeleteOrderReportedProblemsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order_reported_problem.DeleteOrderReportedProblemsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_reported_problem.DeleteOrderReportedProblemsResponse.displayName = 'proto.order_reported_problem.DeleteOrderReportedProblemsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_reported_problem.ListOrderReportedProblemsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order_reported_problem.ListOrderReportedProblemsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_reported_problem.ListOrderReportedProblemsRequest.displayName = 'proto.order_reported_problem.ListOrderReportedProblemsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_reported_problem.ListOrderReportedProblemsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.order_reported_problem.ListOrderReportedProblemsResponse.repeatedFields_, null);
};
goog.inherits(proto.order_reported_problem.ListOrderReportedProblemsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_reported_problem.ListOrderReportedProblemsResponse.displayName = 'proto.order_reported_problem.ListOrderReportedProblemsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_reported_problem.ExportOrderReportedProblemsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order_reported_problem.ExportOrderReportedProblemsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_reported_problem.ExportOrderReportedProblemsRequest.displayName = 'proto.order_reported_problem.ExportOrderReportedProblemsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_reported_problem.ExportOrderReportedProblemsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order_reported_problem.ExportOrderReportedProblemsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_reported_problem.ExportOrderReportedProblemsResponse.displayName = 'proto.order_reported_problem.ExportOrderReportedProblemsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_reported_problem.CreateOrderReportedProblemRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.order_reported_problem.CreateOrderReportedProblemRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_reported_problem.CreateOrderReportedProblemRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_reported_problem.CreateOrderReportedProblemRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    comment: jspb.Message.getFieldWithDefault(msg, 2, ""),
    typeId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    culprit: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_reported_problem.CreateOrderReportedProblemRequest}
 */
proto.order_reported_problem.CreateOrderReportedProblemRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_reported_problem.CreateOrderReportedProblemRequest;
  return proto.order_reported_problem.CreateOrderReportedProblemRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_reported_problem.CreateOrderReportedProblemRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_reported_problem.CreateOrderReportedProblemRequest}
 */
proto.order_reported_problem.CreateOrderReportedProblemRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTypeId(value);
      break;
    case 4:
      var value = /** @type {!proto.model.OrderReportedProblemCulprit} */ (reader.readEnum());
      msg.setCulprit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_reported_problem.CreateOrderReportedProblemRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_reported_problem.CreateOrderReportedProblemRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_reported_problem.CreateOrderReportedProblemRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_reported_problem.CreateOrderReportedProblemRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTypeId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCulprit();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string order_id = 1;
 * @return {string}
 */
proto.order_reported_problem.CreateOrderReportedProblemRequest.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_reported_problem.CreateOrderReportedProblemRequest} returns this
 */
proto.order_reported_problem.CreateOrderReportedProblemRequest.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string comment = 2;
 * @return {string}
 */
proto.order_reported_problem.CreateOrderReportedProblemRequest.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_reported_problem.CreateOrderReportedProblemRequest} returns this
 */
proto.order_reported_problem.CreateOrderReportedProblemRequest.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type_id = 3;
 * @return {string}
 */
proto.order_reported_problem.CreateOrderReportedProblemRequest.prototype.getTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_reported_problem.CreateOrderReportedProblemRequest} returns this
 */
proto.order_reported_problem.CreateOrderReportedProblemRequest.prototype.setTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional model.OrderReportedProblemCulprit culprit = 4;
 * @return {!proto.model.OrderReportedProblemCulprit}
 */
proto.order_reported_problem.CreateOrderReportedProblemRequest.prototype.getCulprit = function() {
  return /** @type {!proto.model.OrderReportedProblemCulprit} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.model.OrderReportedProblemCulprit} value
 * @return {!proto.order_reported_problem.CreateOrderReportedProblemRequest} returns this
 */
proto.order_reported_problem.CreateOrderReportedProblemRequest.prototype.setCulprit = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_reported_problem.UpdateOrderReportedProblemRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.order_reported_problem.UpdateOrderReportedProblemRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_reported_problem.UpdateOrderReportedProblemRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_reported_problem.UpdateOrderReportedProblemRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    comment: jspb.Message.getFieldWithDefault(msg, 2, ""),
    typeId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    culprit: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_reported_problem.UpdateOrderReportedProblemRequest}
 */
proto.order_reported_problem.UpdateOrderReportedProblemRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_reported_problem.UpdateOrderReportedProblemRequest;
  return proto.order_reported_problem.UpdateOrderReportedProblemRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_reported_problem.UpdateOrderReportedProblemRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_reported_problem.UpdateOrderReportedProblemRequest}
 */
proto.order_reported_problem.UpdateOrderReportedProblemRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTypeId(value);
      break;
    case 4:
      var value = /** @type {!proto.model.OrderReportedProblemCulprit} */ (reader.readEnum());
      msg.setCulprit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_reported_problem.UpdateOrderReportedProblemRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_reported_problem.UpdateOrderReportedProblemRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_reported_problem.UpdateOrderReportedProblemRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_reported_problem.UpdateOrderReportedProblemRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTypeId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCulprit();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.order_reported_problem.UpdateOrderReportedProblemRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_reported_problem.UpdateOrderReportedProblemRequest} returns this
 */
proto.order_reported_problem.UpdateOrderReportedProblemRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string comment = 2;
 * @return {string}
 */
proto.order_reported_problem.UpdateOrderReportedProblemRequest.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_reported_problem.UpdateOrderReportedProblemRequest} returns this
 */
proto.order_reported_problem.UpdateOrderReportedProblemRequest.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type_id = 3;
 * @return {string}
 */
proto.order_reported_problem.UpdateOrderReportedProblemRequest.prototype.getTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_reported_problem.UpdateOrderReportedProblemRequest} returns this
 */
proto.order_reported_problem.UpdateOrderReportedProblemRequest.prototype.setTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional model.OrderReportedProblemCulprit culprit = 4;
 * @return {!proto.model.OrderReportedProblemCulprit}
 */
proto.order_reported_problem.UpdateOrderReportedProblemRequest.prototype.getCulprit = function() {
  return /** @type {!proto.model.OrderReportedProblemCulprit} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.model.OrderReportedProblemCulprit} value
 * @return {!proto.order_reported_problem.UpdateOrderReportedProblemRequest} returns this
 */
proto.order_reported_problem.UpdateOrderReportedProblemRequest.prototype.setCulprit = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.order_reported_problem.GetOrderReportedProblemsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_reported_problem.GetOrderReportedProblemsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.order_reported_problem.GetOrderReportedProblemsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_reported_problem.GetOrderReportedProblemsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_reported_problem.GetOrderReportedProblemsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_reported_problem.GetOrderReportedProblemsRequest}
 */
proto.order_reported_problem.GetOrderReportedProblemsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_reported_problem.GetOrderReportedProblemsRequest;
  return proto.order_reported_problem.GetOrderReportedProblemsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_reported_problem.GetOrderReportedProblemsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_reported_problem.GetOrderReportedProblemsRequest}
 */
proto.order_reported_problem.GetOrderReportedProblemsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_reported_problem.GetOrderReportedProblemsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_reported_problem.GetOrderReportedProblemsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_reported_problem.GetOrderReportedProblemsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_reported_problem.GetOrderReportedProblemsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string ids = 1;
 * @return {!Array<string>}
 */
proto.order_reported_problem.GetOrderReportedProblemsRequest.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.order_reported_problem.GetOrderReportedProblemsRequest} returns this
 */
proto.order_reported_problem.GetOrderReportedProblemsRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.order_reported_problem.GetOrderReportedProblemsRequest} returns this
 */
proto.order_reported_problem.GetOrderReportedProblemsRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.order_reported_problem.GetOrderReportedProblemsRequest} returns this
 */
proto.order_reported_problem.GetOrderReportedProblemsRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_reported_problem.GetOrderReportedProblemsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.order_reported_problem.GetOrderReportedProblemsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_reported_problem.GetOrderReportedProblemsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_reported_problem.GetOrderReportedProblemsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsMap: (f = msg.getItemsMap()) ? f.toObject(includeInstance, proto.model.OrderReportedProblemResponse.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_reported_problem.GetOrderReportedProblemsResponse}
 */
proto.order_reported_problem.GetOrderReportedProblemsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_reported_problem.GetOrderReportedProblemsResponse;
  return proto.order_reported_problem.GetOrderReportedProblemsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_reported_problem.GetOrderReportedProblemsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_reported_problem.GetOrderReportedProblemsResponse}
 */
proto.order_reported_problem.GetOrderReportedProblemsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getItemsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.model.OrderReportedProblemResponse.deserializeBinaryFromReader, "", new proto.model.OrderReportedProblemResponse());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_reported_problem.GetOrderReportedProblemsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_reported_problem.GetOrderReportedProblemsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_reported_problem.GetOrderReportedProblemsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_reported_problem.GetOrderReportedProblemsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.model.OrderReportedProblemResponse.serializeBinaryToWriter);
  }
};


/**
 * map<string, model.OrderReportedProblemResponse> items = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.model.OrderReportedProblemResponse>}
 */
proto.order_reported_problem.GetOrderReportedProblemsResponse.prototype.getItemsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.model.OrderReportedProblemResponse>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.model.OrderReportedProblemResponse));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.order_reported_problem.GetOrderReportedProblemsResponse} returns this
 */
proto.order_reported_problem.GetOrderReportedProblemsResponse.prototype.clearItemsMap = function() {
  this.getItemsMap().clear();
  return this;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.order_reported_problem.DeleteOrderReportedProblemsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_reported_problem.DeleteOrderReportedProblemsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.order_reported_problem.DeleteOrderReportedProblemsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_reported_problem.DeleteOrderReportedProblemsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_reported_problem.DeleteOrderReportedProblemsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_reported_problem.DeleteOrderReportedProblemsRequest}
 */
proto.order_reported_problem.DeleteOrderReportedProblemsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_reported_problem.DeleteOrderReportedProblemsRequest;
  return proto.order_reported_problem.DeleteOrderReportedProblemsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_reported_problem.DeleteOrderReportedProblemsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_reported_problem.DeleteOrderReportedProblemsRequest}
 */
proto.order_reported_problem.DeleteOrderReportedProblemsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_reported_problem.DeleteOrderReportedProblemsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_reported_problem.DeleteOrderReportedProblemsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_reported_problem.DeleteOrderReportedProblemsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_reported_problem.DeleteOrderReportedProblemsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string ids = 1;
 * @return {!Array<string>}
 */
proto.order_reported_problem.DeleteOrderReportedProblemsRequest.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.order_reported_problem.DeleteOrderReportedProblemsRequest} returns this
 */
proto.order_reported_problem.DeleteOrderReportedProblemsRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.order_reported_problem.DeleteOrderReportedProblemsRequest} returns this
 */
proto.order_reported_problem.DeleteOrderReportedProblemsRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.order_reported_problem.DeleteOrderReportedProblemsRequest} returns this
 */
proto.order_reported_problem.DeleteOrderReportedProblemsRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_reported_problem.DeleteOrderReportedProblemsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.order_reported_problem.DeleteOrderReportedProblemsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_reported_problem.DeleteOrderReportedProblemsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_reported_problem.DeleteOrderReportedProblemsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_reported_problem.DeleteOrderReportedProblemsResponse}
 */
proto.order_reported_problem.DeleteOrderReportedProblemsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_reported_problem.DeleteOrderReportedProblemsResponse;
  return proto.order_reported_problem.DeleteOrderReportedProblemsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_reported_problem.DeleteOrderReportedProblemsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_reported_problem.DeleteOrderReportedProblemsResponse}
 */
proto.order_reported_problem.DeleteOrderReportedProblemsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_reported_problem.DeleteOrderReportedProblemsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_reported_problem.DeleteOrderReportedProblemsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_reported_problem.DeleteOrderReportedProblemsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_reported_problem.DeleteOrderReportedProblemsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_reported_problem.ListOrderReportedProblemsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.order_reported_problem.ListOrderReportedProblemsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_reported_problem.ListOrderReportedProblemsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_reported_problem.ListOrderReportedProblemsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && protobuf_filter_request_pb.Request.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_reported_problem.ListOrderReportedProblemsRequest}
 */
proto.order_reported_problem.ListOrderReportedProblemsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_reported_problem.ListOrderReportedProblemsRequest;
  return proto.order_reported_problem.ListOrderReportedProblemsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_reported_problem.ListOrderReportedProblemsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_reported_problem.ListOrderReportedProblemsRequest}
 */
proto.order_reported_problem.ListOrderReportedProblemsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new protobuf_filter_request_pb.Request;
      reader.readMessage(value,protobuf_filter_request_pb.Request.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_reported_problem.ListOrderReportedProblemsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_reported_problem.ListOrderReportedProblemsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_reported_problem.ListOrderReportedProblemsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_reported_problem.ListOrderReportedProblemsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      protobuf_filter_request_pb.Request.serializeBinaryToWriter
    );
  }
};


/**
 * optional filter.Request filter = 1;
 * @return {?proto.filter.Request}
 */
proto.order_reported_problem.ListOrderReportedProblemsRequest.prototype.getFilter = function() {
  return /** @type{?proto.filter.Request} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_request_pb.Request, 1));
};


/**
 * @param {?proto.filter.Request|undefined} value
 * @return {!proto.order_reported_problem.ListOrderReportedProblemsRequest} returns this
*/
proto.order_reported_problem.ListOrderReportedProblemsRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_reported_problem.ListOrderReportedProblemsRequest} returns this
 */
proto.order_reported_problem.ListOrderReportedProblemsRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_reported_problem.ListOrderReportedProblemsRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.order_reported_problem.ListOrderReportedProblemsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_reported_problem.ListOrderReportedProblemsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.order_reported_problem.ListOrderReportedProblemsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_reported_problem.ListOrderReportedProblemsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_reported_problem.ListOrderReportedProblemsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    counters: (f = msg.getCounters()) && protobuf_filter_counters_pb.Counters.toObject(includeInstance, f),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    protobuf_model_merged_pb.OrderReportedProblemResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_reported_problem.ListOrderReportedProblemsResponse}
 */
proto.order_reported_problem.ListOrderReportedProblemsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_reported_problem.ListOrderReportedProblemsResponse;
  return proto.order_reported_problem.ListOrderReportedProblemsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_reported_problem.ListOrderReportedProblemsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_reported_problem.ListOrderReportedProblemsResponse}
 */
proto.order_reported_problem.ListOrderReportedProblemsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new protobuf_filter_counters_pb.Counters;
      reader.readMessage(value,protobuf_filter_counters_pb.Counters.deserializeBinaryFromReader);
      msg.setCounters(value);
      break;
    case 2:
      var value = new protobuf_model_merged_pb.OrderReportedProblemResponse;
      reader.readMessage(value,protobuf_model_merged_pb.OrderReportedProblemResponse.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_reported_problem.ListOrderReportedProblemsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_reported_problem.ListOrderReportedProblemsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_reported_problem.ListOrderReportedProblemsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_reported_problem.ListOrderReportedProblemsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCounters();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      protobuf_filter_counters_pb.Counters.serializeBinaryToWriter
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      protobuf_model_merged_pb.OrderReportedProblemResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional filter.Counters counters = 1;
 * @return {?proto.filter.Counters}
 */
proto.order_reported_problem.ListOrderReportedProblemsResponse.prototype.getCounters = function() {
  return /** @type{?proto.filter.Counters} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_counters_pb.Counters, 1));
};


/**
 * @param {?proto.filter.Counters|undefined} value
 * @return {!proto.order_reported_problem.ListOrderReportedProblemsResponse} returns this
*/
proto.order_reported_problem.ListOrderReportedProblemsResponse.prototype.setCounters = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_reported_problem.ListOrderReportedProblemsResponse} returns this
 */
proto.order_reported_problem.ListOrderReportedProblemsResponse.prototype.clearCounters = function() {
  return this.setCounters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_reported_problem.ListOrderReportedProblemsResponse.prototype.hasCounters = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated model.OrderReportedProblemResponse items = 2;
 * @return {!Array<!proto.model.OrderReportedProblemResponse>}
 */
proto.order_reported_problem.ListOrderReportedProblemsResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.model.OrderReportedProblemResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, protobuf_model_merged_pb.OrderReportedProblemResponse, 2));
};


/**
 * @param {!Array<!proto.model.OrderReportedProblemResponse>} value
 * @return {!proto.order_reported_problem.ListOrderReportedProblemsResponse} returns this
*/
proto.order_reported_problem.ListOrderReportedProblemsResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.model.OrderReportedProblemResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.OrderReportedProblemResponse}
 */
proto.order_reported_problem.ListOrderReportedProblemsResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.model.OrderReportedProblemResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.order_reported_problem.ListOrderReportedProblemsResponse} returns this
 */
proto.order_reported_problem.ListOrderReportedProblemsResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_reported_problem.ExportOrderReportedProblemsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.order_reported_problem.ExportOrderReportedProblemsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_reported_problem.ExportOrderReportedProblemsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_reported_problem.ExportOrderReportedProblemsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && protobuf_filter_request_pb.Request.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_reported_problem.ExportOrderReportedProblemsRequest}
 */
proto.order_reported_problem.ExportOrderReportedProblemsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_reported_problem.ExportOrderReportedProblemsRequest;
  return proto.order_reported_problem.ExportOrderReportedProblemsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_reported_problem.ExportOrderReportedProblemsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_reported_problem.ExportOrderReportedProblemsRequest}
 */
proto.order_reported_problem.ExportOrderReportedProblemsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new protobuf_filter_request_pb.Request;
      reader.readMessage(value,protobuf_filter_request_pb.Request.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_reported_problem.ExportOrderReportedProblemsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_reported_problem.ExportOrderReportedProblemsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_reported_problem.ExportOrderReportedProblemsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_reported_problem.ExportOrderReportedProblemsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      protobuf_filter_request_pb.Request.serializeBinaryToWriter
    );
  }
};


/**
 * optional filter.Request filter = 1;
 * @return {?proto.filter.Request}
 */
proto.order_reported_problem.ExportOrderReportedProblemsRequest.prototype.getFilter = function() {
  return /** @type{?proto.filter.Request} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_request_pb.Request, 1));
};


/**
 * @param {?proto.filter.Request|undefined} value
 * @return {!proto.order_reported_problem.ExportOrderReportedProblemsRequest} returns this
*/
proto.order_reported_problem.ExportOrderReportedProblemsRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_reported_problem.ExportOrderReportedProblemsRequest} returns this
 */
proto.order_reported_problem.ExportOrderReportedProblemsRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_reported_problem.ExportOrderReportedProblemsRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_reported_problem.ExportOrderReportedProblemsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.order_reported_problem.ExportOrderReportedProblemsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_reported_problem.ExportOrderReportedProblemsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_reported_problem.ExportOrderReportedProblemsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    pb_export: (f = msg.getExport()) && protobuf_filter_export_pb.ExportResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_reported_problem.ExportOrderReportedProblemsResponse}
 */
proto.order_reported_problem.ExportOrderReportedProblemsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_reported_problem.ExportOrderReportedProblemsResponse;
  return proto.order_reported_problem.ExportOrderReportedProblemsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_reported_problem.ExportOrderReportedProblemsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_reported_problem.ExportOrderReportedProblemsResponse}
 */
proto.order_reported_problem.ExportOrderReportedProblemsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new protobuf_filter_export_pb.ExportResponse;
      reader.readMessage(value,protobuf_filter_export_pb.ExportResponse.deserializeBinaryFromReader);
      msg.setExport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_reported_problem.ExportOrderReportedProblemsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_reported_problem.ExportOrderReportedProblemsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_reported_problem.ExportOrderReportedProblemsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_reported_problem.ExportOrderReportedProblemsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExport();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      protobuf_filter_export_pb.ExportResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional filter.ExportResponse export = 1;
 * @return {?proto.filter.ExportResponse}
 */
proto.order_reported_problem.ExportOrderReportedProblemsResponse.prototype.getExport = function() {
  return /** @type{?proto.filter.ExportResponse} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_export_pb.ExportResponse, 1));
};


/**
 * @param {?proto.filter.ExportResponse|undefined} value
 * @return {!proto.order_reported_problem.ExportOrderReportedProblemsResponse} returns this
*/
proto.order_reported_problem.ExportOrderReportedProblemsResponse.prototype.setExport = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_reported_problem.ExportOrderReportedProblemsResponse} returns this
 */
proto.order_reported_problem.ExportOrderReportedProblemsResponse.prototype.clearExport = function() {
  return this.setExport(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_reported_problem.ExportOrderReportedProblemsResponse.prototype.hasExport = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.order_reported_problem);
